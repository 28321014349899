.layoutBox {
  min-height: calc(100vh - 1.76rem);
  padding: 0.2rem;
  font-family: PingFangSC-Regular;
}
.list-cell-li {
  padding: 0.2rem 0;
  margin: 0 0.2rem;
  border-bottom: 1px solid #EDEDED;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.list-cell-li:last-child {
  border-bottom: 0;
}
.list-cell-li .address {
  font-size: 0.24rem;
  color: #333333;
  width: 3.72rem;
  word-break: break-all;
  text-align: right;
}
.list-cell-li .typebtn {
  padding: 0.02rem 0.12rem;
  background: #EFF7FF;
  border-radius: 0.04rem;
  margin-left: 0.1rem;
}
.comTips {
  color: #666666;
  font-size: 0.28rem;
  line-height: 0.45rem;
}
.comTips.comTipsPadd {
  color: #666666;
  font-size: 0.28rem;
  line-height: 0.4rem;
  padding: 0.25rem;
  padding-bottom: 0.8rem;
}
.comTips .caption {
  color: #333333;
  font-size: 0.28rem;
  font-weight: bold;
  line-height: 0.45rem;
  margin-bottom: 0.25rem;
}
.comTips p {
  margin-bottom: 0.25rem;
}
.comTips p:last-child {
  margin-bottom: 0;
}
.comBtnStyle {
  color: #333333;
  font-size: 0.32rem;
  text-align: center;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #FAD538;
  border-radius: 0.16rem;
}
.contWhiteBox {
  margin-bottom: 0.2rem;
  border-radius: 0.16rem;
}
.mainFontLightColor {
  font-size: 0.28rem;
}
.mainFontColor {
  color: #333333 !important;
  font-size: 0.24rem;
}
